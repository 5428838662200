import React, { useState, useEffect } from 'react';
import styles from './LandingHeader.module.scss'
import logo from '../../../../assets/images/logo.png'
import { ButtonMUI } from '../../../../shared'
import { Link } from 'react-router-dom';
import { rootMainPath } from '../../../../routes/paths';

const LandingHeader = () => {
    const [scrolled, setScrolled] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className={`${styles.root} ${scrolled ? styles.scrolled : ''}`}>
            <div className="container">
                <div className={styles.wrapper}>
                    <Link to={'/'}>
                        <img className={styles.logo} src={logo} alt='Logo' />
                    </Link>
                    <a href={'https://whitepaper.cryptochests.com/crypto-chests/introduction'} target='_blank' className={styles.link}>
                        White paper
                    </a>
                    <ButtonMUI to={rootMainPath} className={styles.button}>
                        Join
                    </ButtonMUI>
                </div>
            </div>
        </div>
    )
}

export default LandingHeader